<template>
  <div>
    <Header
      :items="headerButtons"
      :title="$t('dataSets.dataSets').toString()"
      @create-data-set="createDataSet"
    >
      <template v-if="!isPresetProject" #afterItems>
        <file-upload
          ref="upload"
          v-b-popover.hover.top="$t('dataSets.importDataSet')"
          accept="application/json,.yml,.yaml"
          class="btn btn-outline-primary btn-hover ml-2"
          extensions="json,yaml,yml"
          @input-file="importDataSet"
        >
          <i class="fa-light fa-file-import"></i>
        </file-upload>
      </template>
    </Header>
    <TableHelper
      ref="table"
      :actions="actions"
      :enable-filter="false"
      :fields="fields"
      :items="dataSets"
      :meta="meta"
      :filterable="filterable"
      default-order-by="name"
      loading-key="loadDataSets"
      :exportable="true"
      @delete="deleteDataSet"
      @edit="routeToEdit"
      @export="exportDataSet"
      @open="routeToEditor"
      @showVersions="showVersions"
      @reload-data="loadDataSets"
      @export-csv="exportCsv"
    />
    <BackupModal
      v-model="showVersionsModal"
      :object="selectedDataSet"
      classname="DataSet"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SET_DATA_SET } from "@/core/services/store/dataSets.module";
import DataSets from "@/components/DataSets/dataSets";
import { formatDate } from "@/components/Tools/helperFunctions";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import FileUpload from "vue-upload-component";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  components: { FileUpload, BackupModal, Header, TableHelper },
  props: {},
  data() {
    return {
      dataSets: [],
      meta: {},
      fields: [
        {
          key: "name",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "updated_at",
          label: this.$t("table.updatedAt"),
          sortable: true
        }
      ],
      selectedDataSet: null,
      showVersionsModal: false,
      // Filterable fields
      filterable: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters("dataSets", ["isBusy"]),
    ...mapGetters("instance", ["instance"]),
    ...mapGetters([
      "selectedProject",
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      const headerButtons = [
        {
          type: "button",
          title: this.$t("dataSets.createDataSet"),
          icon: this.$store.getters["config/icons"].add,
          emit: "create-data-set"
        }
      ];
      return this.isPresetProject || !this.isDevPresetVersion
        ? []
        : headerButtons;
    },
    actions: function () {
      let actions = [
        {
          key: "open",
          icon: this.$store.getters["config/icons"].editor,
          emit: "open",
          tooltip: this.$t("dataSets.openEditor")
        },
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("dataSets.configure")
        },
        {
          key: "export",
          icon: this.$store.getters["config/icons"].exportEntity,
          emit: "export",
          tooltip: this.$t("dataSets.export")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return actions;
      }
      actions.push({
        key: "delete",
        icon: this.$store.getters["config/icons"].delete,
        emit: "delete",
        tooltip: this.$t("general.delete")
      });
      return actions;
    }
  },
  watch: {
    selectedProject: function () {
      this.loadDataSets();
    },
    selectedPresetVersion: function () {
      this.loadDataSets();
    }
  },
  mounted() {
    this.loadDataSets();
  },
  methods: {
    ...mapActions("dataSets", [SET_DATA_SET]),
    loadDataSets(payload = {}) {
      this.dataSets = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({ key: "loadDataSets" });
      let params = { ...this.params(), ...payload };
      const { filter } = useCurrentProjectFilter();
      DataSets.getAll(params, filter.value)
        .then(response => {
          this.dataSets = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
          removeEventFromLoadingQueue({ key: "loadDataSets" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    routeToEditor(item) {
      this.$router.push({
        name: "projectDataSetsEditor",
        params: { id: item.item.id }
      });
    },
    routeToEdit(item) {
      this.$router.push({
        name: "projectDataSetsDetails",
        params: { id: item.item.id }
      });
    },
    showVersions(data) {
      this.selectedDataSet = data.item;
      this.showVersionsModal = true;
    },
    exportDataSet(item) {
      let id = item?.item?.id;
      let name = item?.item?.name;

      addEventToLoadingQueue({ key: "exportDataSetAction" });
      DataSets.export(id, "json")
        .then(response => {
          removeEventFromLoadingQueue({
            key: "exportDataSetAction",
            type: "success",
            prefix: "dataSets",
            name: "exportSuccess"
          });
          const blob = new Blob([JSON.stringify(response)], {
            type: "application/json"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "[xentral_connect]" + name.replace(/ /g, "_") + "_" + id;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$toast.fire({
            title: this.$t("workflowDesigner.workflowExportSuccess", {
              name: name
            }),
            icon: "success"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    deleteDataSet(item) {
      let id = item.item.id;
      let name = item.item.name;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataSets.deleteTitle"),
          text: this.$t("dataSets.deleteText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#5b64ee"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteDataSetAction(id);
        });
    },
    deleteDataSetAction(id) {
      addEventToLoadingQueue({ key: "deleteDataSetAction" });
      DataSets.delete(id)
        .then(response => {
          if (response.data.success) {
            removeEventFromLoadingQueue({
              key: "deleteDataSetAction",
              type: "success",
              prefix: "dataSets",
              name: "deletedSuccess"
            });
            this.loadDataSets();
            return;
          }
          let errors = Object.keys(response.data.related);
          this.$toast.fire({
            icon: "error",
            title: this.$t("dataSets.deletedError"),
            html: `<li>${errors.join("</li><li>")}</li>`
          });
          removeEventFromLoadingQueue({ key: "deleteDataSetAction" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    createDataSet() {
      this.$router.push({ name: "projectDataSetsCreate" });
    },
    importDataSet(newFile, oldFile) {
      if (newFile && !oldFile) {
        addEventToLoadingQueue({ key: "loadingFile" });

        let importData = new FormData();
        importData.append("file", newFile.file);

        if (this.selectedProject.id) {
          importData.append("project_id", this.selectedProject.id);
        }

        DataSets.import(importData)
          .then(() => {
            this.loadDataSets();
            removeEventFromLoadingQueue({
              key: "loadingFile",
              type: "success",
              prefix: "dataSets",
              name: "dataSetImportSuccess"
            });
          })
          .catch(error => {
            this.$error(error);
          });
      }
    },
    exportCsv(params) {
      DataSets.exportCsv(params).catch(error => {
        this.$error(error);
      });
    },
    formatDate(value) {
      if (value.length) {
        return formatDate(value);
      }
      return "";
    }
  }
};
</script>
