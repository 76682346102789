<template>
  <div>
    <TableWrapper
      :meta="meta"
      :no-route-params="true"
      :disable-search="true"
      @reload-data="reload"
    >
      <b-overlay
        :show="isBusy"
        class="overlay-spinner-custom"
        rounded
        variant="white"
        spinner-variant="primary"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="dataTable table"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #head()="{ column }">
            {{ column }}
          </template>
          <template #cell()="data">
            <span v-if="JSON.stringify(data.value).length > maxDataLength">
              {{ formatCellData(data.value) }}
              <span
                class="label label-inline label-light-secondary px-1 cursor-pointer"
                @click="showFullValue(data.field.key, data.value)"
                >[...]
              </span>
            </span>
            <span v-else>
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </b-overlay>
    </TableWrapper>
    <b-modal
      id="datasets-preview-modal"
      v-model="modal.show"
      size="xl"
      :title="modal.title.toString()"
      centered
      scrollable
      @hidden="closeModal"
    >
      <vue-json-pretty
        v-if="typeof modal.data === 'object'"
        highlight-mouseover-node
        :data="modal.data"
      />
      <span v-else>{{ modal.data }}</span>
      <template #modal-footer>
        <button class="btn btn-secondary" @click="copyValue">
          {{ $t("general.copy") }}
        </button>
        <button class="btn btn-primary" @click="closeModal">
          {{ $t("general.close") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper.vue";
import DataSets from "@/components/DataSets/dataSets";
import {
  DECREMENT_IS_BUSY,
  INCREMENT_IS_BUSY,
  SET_DEBUG
} from "@/core/services/store/dataSets.module";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { SET_ROUTE_PARAMS_BY_KEY } from "@/core/services/store/route.module";

export default {
  components: { TableWrapper },
  props: {},
  data() {
    return {
      pageSize: 15,
      form: [
        {
          label: "Limit",
          name: "limit",
          type: "select",
          options: [5, 15, 25, 50, 100],
          default: 15,
          class: "col-md-6"
        },
        {
          label: "Offset",
          name: "offset",
          type: "select",
          options: [0, 5, 15, 25, 50, 100],
          default: 15,
          class: "col-md-6"
        }
      ],
      maxDataLength: 40,
      modal: {
        show: false,
        data: undefined,
        dataString: "",
        title: "",
        editMode: false
      }
    };
  },
  computed: {
    ...mapGetters("dataSets", [
      "dataSet",
      "debug",
      "testStages",
      "isBusy",
      "parameters"
    ]),
    items: function () {
      return this.debug.data ?? [];
    },
    fields: function () {
      return this.debug.fieldNames ?? [];
    },
    meta: function () {
      return this.debug.meta ?? {};
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [
      INCREMENT_IS_BUSY,
      DECREMENT_IS_BUSY,
      SET_DEBUG
    ]),
    reload(params) {
      this.pageSize = params?.perPage ?? 15;

      this.$store.dispatch("route/" + SET_ROUTE_PARAMS_BY_KEY, {
        key: "perPage",
        value: this.pageSize
      });
      this[INCREMENT_IS_BUSY]();
      // Define payload
      const payload = {
        data_structure_id: this.dataSet.data_structure_id,
        dataSetStages: this.testStages,
        limit: params.perPage,
        page: params.page,
        parameters: this.parameters
      };
      DataSets.runStages(payload)
        .then(response => {
          if (response.error) {
            this.$error(response.error);
          }
          // Set result in store
          this[SET_DEBUG](response);
          this[DECREMENT_IS_BUSY]();
        })
        .catch(error => {
          this.$error(error);
          this[DECREMENT_IS_BUSY]();
        });
    },
    formatCellData(value) {
      let asString = JSON.stringify(value);
      if (asString.length <= this.maxDataLength) {
        return asString;
      }
      return asString.substring(0, this.maxDataLength);
    },
    showFullValue(field, value, editMode = false) {
      this.modal = {
        title: field,
        data: value,
        dataString: JSON.stringify(value, null, "\t"),
        show: true,
        editMode: editMode
      };
    },
    copyValue() {
      let dataString = JSON.stringify(this.modal.data);
      copyToClipboard(dataString);
    },
    closeModal() {
      this.modal = {
        show: false,
        data: undefined,
        title: "",
        editMode: false
      };
    }
  }
};
</script>

<style lang="scss">
.overlay-spinner-custom {
  .spinner-border {
    width: 25px;
    height: 25px;
  }
}

#datasets-preview-modal {
  height: 100vh;
  overflow: hidden;

  .modal-dialog {
    height: auto;
    max-height: calc(100% - 45px);
    overflow: hidden;

    .modal-content {
      height: 100%;
      overflow: hidden;

      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.nav-tabs {
  .nav-link {
    margin-bottom: -2px;
  }
}
</style>
