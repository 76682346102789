<template>
  <div>
    <Header
      :title="$t('dataSets.createDataSet').toString()"
      :items="headerButtons"
      :show-back-button="true"
      @back="$router.push({ name: 'projectDataSets' })"
      @save="createDataSet"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="dataSet"
          :config="formConfig"
          :form="form"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import DataStructures from "@/components/DataStructures/dataStructures";
import DataSets from "@/components/DataSets/dataSets";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { Header, FormHelper },
  props: {},
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      dataSet: {
        name: "",
        data_structure_id: "",
        projects: []
      },
      form: [
        {
          name: "name",
          type: "text",
          label: "name",
          validations: {
            required: true
          }
        },
        {
          name: "projects",
          type: "multiselect",
          label: "projects",
          options: [],
          validations: {
            required: true
          }
        },
        {
          name: "data_structure_id",
          type: "select",
          label: "selectDataStructure",
          options: [],
          validations: {
            required: true
          }
        }
      ],
      formConfig: {
        snippetPrefix: "dataSets",
        enableVariables: false
      }
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedProject"])
  },
  watch: {
    projects: function () {
      this.getProjectOptions();
    }
  },
  mounted() {
    this.getProjectOptions();

    this.dataSet.projects = [this.selectedProject.id];
    this.loadDataStructures();
  },
  methods: {
    getProjectOptions() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.projects?.length
        ? this.projects.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          })
        : [];
    },
    onChange(item) {
      if (item.name === "projects") {
        this.dataSet.projects = item.value;
        this.loadDataStructures();
      }
    },
    loadDataStructures() {
      addEventToLoadingQueue({ key: "loadDataStructures" });
      const formDataStructures = Object.values(this.form).find(
        element => element.name === "data_structure_id"
      );
      formDataStructures.options = [];

      const params = { noPagination: true };
      if (this.dataSet.projects.length !== 0) {
        params.projects = this.dataSet.projects;
      }
      // Get all datastructures
      DataStructures.getAll(params)
        .then(response => {
          // Set datastructures as select options
          response.data.forEach(option => {
            let type = option.owner_type.includes("DataSet")
              ? " - DataSet"
              : " - DataStructure";
            this.form[2].options.push({
              label: option.label + type,
              value: option.id
            });
          });
          removeEventFromLoadingQueue({ key: "loadDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    createDataSet() {
      addEventToLoadingQueue({ key: "createDataSet" });
      // Get data of new data set
      const data = Object.assign({}, this.dataSet);
      // Store new data set
      DataSets.store(data)
        .then(response => {
          // Get id of new data set and route to editor
          let id = response.data.id;
          removeEventFromLoadingQueue({ key: "createDataSet" });
          this.$router.push({
            name: "projectDataSetsEditor",
            params: { id: id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
