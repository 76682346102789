<template>
  <div>
    <b-tabs v-model="activeTab">
      <b-tab :title="$t('dataSets.preview')" class="px-3 pt-5">
        <Preview />
      </b-tab>
      <b-tab :title="$t('dataSets.parameters')" class="p-3 pt-5">
        <Parameters />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Preview from "@/components/DataSets/Editor/Debug/Preview.vue";
import Parameters from "@/components/DataSets/Editor/Debug/Parameters.vue";

export default {
  components: {
    Preview,
    Parameters
  },
  props: {},
  data() {
    return {
      activeTab: 0
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
