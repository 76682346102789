import _ from "lodash";

export function getStageConfigsByReturnKey(dataSetStages, baseStages) {
  // Loop through stages
  dataSetStages.forEach(stage => {
    // Get base stage
    let baseStage = baseStages[stage.stage_type];
    // If stage is SourceStage
    if (!baseStage) {
      return;
    }
    // Resolve return keys if set
    stage.config = getValuesByReturnKey(stage.config, baseStage.config);
    if (stage.subStages?.length) {
      stage.subStages.forEach(subStage => {
        // Get base stage
        let baseSubStage = baseStages[subStage.stage_type];
        // Set values by return keys if set
        subStage.config = getValuesByReturnKey(
          subStage.config,
          baseSubStage.config
        );
      });
    }
  });
  return dataSetStages;
}
export function setStageConfigsByReturnKey(dataSetStages, baseStages) {
  // Loop through stages
  dataSetStages.forEach(stage => {
    // Get base stage
    let baseStage = baseStages[stage.stage_type];
    // If stage is SourceStage
    if (!baseStage) {
      return;
    }
    // Set values by return keys if set
    stage.config = setValuesByReturnKey(stage.config, baseStage.config);
    if (stage.subStages?.length) {
      stage.subStages.forEach(subStage => {
        // Get base stage
        let baseSubStage = baseStages[subStage.stage_type];
        // Set values by return keys if set
        subStage.config = setValuesByReturnKey(
          subStage.config,
          baseSubStage.config
        );
      });
    }
  });
  return dataSetStages;
}
function getValuesByReturnKey(config, fields) {
  let values = {};
  // Loop through each field
  fields.forEach(field => {
    // Check if field is hidden because of dependency
    let fieldIsShown =
      !field.dependsOn ||
      !!field.dependsOn.find(f => f.values.includes(config[f.name]));
    // If field is hidden, skip
    if (!fieldIsShown) {
      return;
    }
    // Get value from config by either returnKey or name
    let value = _.get(config, field.returnKey ?? field.name);
    // Return if value is not set
    if (value === undefined) {
      return;
    }
    // If field is of type group
    if (field.type === "group") {
      // Map through each group and call this function on it
      value = value.map(group => {
        return getValuesByReturnKey(group, field.fields);
      });
    }
    // Set value by name
    values[field.name] = value;
  });
  // Return values
  return values;
}
function setValuesByReturnKey(config, fields) {
  let values = {};
  // Loop through config
  Object.keys(config).forEach(name => {
    let value = config[name];
    // Find formField of value by name
    let formField = fields.find(f => f.name === name);
    // If field is of type group
    if (formField.type === "group") {
      // Map through each group and call this function on it
      value = value.map(group => {
        return setValuesByReturnKey(group, formField.fields);
      });
    }
    // Set value in new values object by either returnKey or name
    _.set(values, formField.returnKey ?? name, value);
  });
  // Return values
  return values;
}
