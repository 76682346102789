<template>
  <div>
    <div class="text-right">
      <button
        class="btn btn-icon btn-sm btn-hover-light-primary"
        @click="reloadConfigValues"
      >
        <i class="fal fa-sync-alt" :class="{ spin: isBusy }" />
      </button>
      <button class="btn btn-primary ml-3" @click="addParameter">
        <i class="fal fa-plus" />
        {{ $t("dataSets.addParameter") }}
      </button>
    </div>
    <div class="dataTables_wrapper">
      <b-table
        responsive
        :items="items"
        :fields="fields"
        class="dataTable table table-head-custom"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
      >
        <template #cell(type)="data">
          <i
            v-b-popover.hover.top="$t('dataSets.' + data.item.type)"
            :class="icon[data.item.type]"
          />
        </template>
        <template #cell(actions)="data">
          <div class="text-right">
            <button
              v-if="data.item.type === 'parameter'"
              class="btn btn-icon btn-light btn-sm"
              @click="editParameter(data.item.name)"
            >
              <i class="fal fa-pen"></i>
            </button>
            <button
              v-if="data.item.type === 'parameter'"
              class="btn btn-icon btn-light btn-sm ml-1"
              @click="deleteParameter(data.item.name)"
            >
              <i class="fal fa-trash"></i>
            </button>
            <button
              v-if="data.item.type === 'configValue'"
              class="btn btn-icon btn-light btn-sm"
              @click="editConfigValue(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </button>
          </div>
        </template>
      </b-table>
    </div>
    <ParameterModal
      v-model="modal.showModal"
      :is-edit="modal.isEdit"
      :edit-parameter="modal.parameter"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ParameterModal from "@/components/DataSets/Components/ParameterModal.vue";
import {
  DECREMENT_IS_BUSY,
  INCREMENT_IS_BUSY,
  SET_PARAMETERS
} from "@/core/services/store/dataSets.module";
import {
  LOAD_CONFIG_VALUES,
  LOAD_PRESET_VALUES,
  SET_CUSTOM_VARIABLES
} from "@/core/services/store/variables_v1.module";
import _ from "lodash";

export default {
  components: { ParameterModal },
  props: {},
  data() {
    return {
      pageSize: 15,
      modal: {
        showModal: false,
        isEdit: false,
        parameter: {}
      },
      icon: {
        parameter: "fal fa-brackets-curly",
        configValue: "fal fa-gears"
      }
    };
  },
  computed: {
    ...mapGetters("dataSets", ["parameters", "isBusy"]),
    ...mapGetters("variables", ["configValues"]),
    items: function () {
      // Get parameters as items
      let parameters = [];
      this.parameters.forEach(param => {
        parameters.push({
          label: param.name,
          name: param.name,
          value: param.value,
          type: "parameter"
        });
      });
      // Set configValues
      let configValues = this.configValues.map(cv => {
        cv.type = "configValue";
        return cv;
      });
      return [...parameters, ...configValues];
    },
    fields: function () {
      return [
        {
          key: "label",
          label: this.$t("dataSets.label")
        },
        {
          key: "name",
          label: this.$t("dataSets.name")
        },
        {
          key: "type",
          label: this.$t("dataSets.type")
        },
        {
          key: "value",
          label: this.$t("dataSets.value"),
          formatter: val =>
            typeof val === "object" ? JSON.stringify(val) : String(val)
        },
        {
          key: "actions",
          label: this.$t("dataSets.actions")
        }
      ];
    },
    meta: function () {
      return {
        current_page: 1,
        from: 1,
        to: 1,
        last_page: 1,
        per_page: this.pageSize,
        total: this.items.length
      };
    }
  },
  watch: {
    parameters: function () {
      const payload = {
        name: "dataSetParameters",
        prefix: "parameter",
        variables: this.parameters,
        showValue: true,
        text: "name"
      };
      this[SET_CUSTOM_VARIABLES](payload);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [
      SET_PARAMETERS,
      INCREMENT_IS_BUSY,
      DECREMENT_IS_BUSY
    ]),
    ...mapActions("variables", [
      LOAD_CONFIG_VALUES,
      SET_CUSTOM_VARIABLES,
      LOAD_PRESET_VALUES
    ]),
    addParameter() {
      // Show modal
      this.modal = {
        showModal: true,
        isEdit: false,
        parameter: {}
      };
    },
    editParameter(name) {
      // Get parameter to edit by name
      let parameter = this.parameters.find(p => p.name === name);
      // Show modal
      this.modal = {
        showModal: true,
        isEdit: true,
        parameter: _.cloneDeep(parameter)
      };
    },
    deleteParameter(name) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataSets.parameterDeleteTitle"),
          text: this.$t("dataSets.parameterDeleteText"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#5b64ee", // Danger color
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(result => {
          if (result.isConfirmed) {
            let parameters = [...this.parameters];
            parameters = parameters.filter(p => p.name !== name);
            this[SET_PARAMETERS](parameters);
          }
        });
    },
    editConfigValue(id) {
      const route = this.$router.resolve({
        name: "projectSettingsConfigDetails",
        params: { configId: id }
      });
      window.open(route.href, "_blank");
    },
    reloadConfigValues() {
      this[INCREMENT_IS_BUSY]();
      this[LOAD_CONFIG_VALUES]().then(() => this[DECREMENT_IS_BUSY]());
      this[LOAD_PRESET_VALUES]().then(() => this[DECREMENT_IS_BUSY]());
    }
  }
};
</script>
