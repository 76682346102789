<template>
  <div id="stages">
    <div v-if="dataSet" class="timeline timeline-3 mr-5">
      <!-----    START: Timeline items    ----->
      <div class="timeline-items">
        <!-----    START: SourceStage    ----->
        <div v-if="dataSet.dataSetStages?.length" class="timeline-item">
          <Stage :stage="dataSet.dataSetStages[0]" :order-index="-1" />
        </div>
        <!-----    END: SourceStage    ----->
        <!-----    START: Draggable items    ----->
        <draggable
          :key="draggableKey"
          v-model="draggableStages"
          handle=".timeline-media.stage"
          animation="200"
          @start="dragStart"
          @end="dragEnd"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <!-----    START: Items    ----->
            <div
              v-for="(stage, i) in draggableStages"
              :key="i"
              :class="[
                'timeline-item timeline-item-' + i,
                i + 1 === draggableStages.length ? 'pb-5' : ''
              ]"
            >
              <!-----    START: SubStages    ----->
              <SubStages
                v-if="stage.hasSubStagesEnabled"
                :parent-stage-order-index="stage.order_index"
              />
              <!-----    END: SubStages    ----->
              <!-----    START: Stages    ----->
              <Stage :stage="stage" :order-index="stage.order_index" />
              <!-----    END: Stages    ----->
            </div>
            <!-----    END: Items    ----->
          </transition-group>
        </draggable>
        <!-----    END: Draggable items    ----->
        <AddStage />
      </div>
      <!-----    END: Timeline items    ----->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  SELECT_STAGE,
  UPDATE_ORDER_INDEX
} from "@/core/services/store/dataSets.module";
import Stage from "@/components/DataSets/Components/Stage.vue";
import SubStages from "@/components/DataSets/Editor/SubStages.vue";
import { setVariablesByStage } from "@/components/DataSets/Helper/functions";
import AddStage from "@/components/DataSets/Components/AddStage.vue";

export default {
  components: { AddStage, Stage, SubStages },
  props: {},
  data() {
    return {
      draggableKey: 1,
      drag: false
    };
  },
  computed: {
    ...mapGetters("dataSets", ["dataSet"]),
    draggableStages: {
      get: function () {
        return (
          this.dataSet.dataSetStages?.filter(stage => stage.order_index >= 0) ??
          []
        );
      },
      set: function (stages) {
        let fixed = this.dataSet.dataSetStages.filter(
          stage => stage.order_index < 0
        );
        this[UPDATE_ORDER_INDEX]([...fixed, ...stages]);
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [UPDATE_ORDER_INDEX, SELECT_STAGE]),
    resetSelectedStage() {
      const payload = { stageOrderIndex: null, subStageOrderIndex: null };
      this[SELECT_STAGE](payload);
      setVariablesByStage();
    },
    dragStart() {
      this.drag = true;
      this.resetSelectedStage();
    },
    dragEnd() {
      this.drag = false;
      this.draggableKey++;
      this[UPDATE_ORDER_INDEX]();
    }
  }
};
</script>

<style lang="scss">
#stages {
  .timeline {
    .timeline-item {
      border-left-color: #ebedf3;
      .timeline-media {
        &.draggable {
          cursor: move;
        }
        &.active {
          border-color: var(--primary);
          border-width: 1px;
        }
      }
    }
  }
}
</style>
