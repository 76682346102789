import store from "@/core/services/store";
import { mapActions, mapGetters } from "vuex";
import { SET_CUSTOM_VARIABLES } from "@/core/services/store/variables_v1.module";
import _ from "lodash";

const prefixes = {
  source: "source", // Fields from data structure
  output: "output", // By transformers added fields
  foreign: "foreign", // By lookup added fields
  local: "local" // Source fields available inside sub pipeline
};

const getters = {
  $store: store,
  ...mapGetters("dataSets", [
    "dataSet",
    "selectedStage",
    "selectedStageOrderIndex",
    "selectedSubStageOrderIndex",
    "selectedDataStructure",
    "dataStructures",
    "selectedStage"
  ])
};

const actions = {
  $store: store,
  ...mapActions("dataSets", []),
  ...mapActions("variables", [SET_CUSTOM_VARIABLES])
};

/*
 * All fields are stored in the following structure while being transformed:
 * const field = {
 *    name: string,
 *    prefix: string (typeof const prefixes)
 * }
 * This has the advantage of not having to split up prefix and name when comparing.
 * After being transformed, filtered, etc... prefix and field name will be joined, sorted
 * and then stored inside the store.
 */

export function setVariablesByStage() {
  let selectedStageOrderIndex = getters.selectedStageOrderIndex(),
    selectedSubStageOrderIndex = getters.selectedSubStageOrderIndex();
  let variables;
  if (selectedStageOrderIndex === null) {
    // If no stage is selected, remove all variables
    const payload = {
      name: "dataSetFields",
      variables: []
    };
    actions[SET_CUSTOM_VARIABLES](payload);
    return;
  } else if (selectedSubStageOrderIndex === null) {
    variables = getStageVariables();
  } else {
    variables = getSubStageVariables();
  }
  setVariablesInStore(variables);
}

function getStageVariables() {
  // Get necessary data from store
  let selectedDataStructure = getters.selectedDataStructure(),
    selectedStageOrderIndex = getters.selectedStageOrderIndex();
  // If no stage is selected, return empty array
  if (selectedStageOrderIndex === null) {
    return [];
  }
  let availableSystemFieldsKeys = Object.keys(
    selectedDataStructure?.systemFields
  );
  let availableSystemFields = availableSystemFieldsKeys.map(key => ({
    name: key,
    prefix: prefixes.source
  }));
  // Initially, all fields from selected data structure are available
  let availableFields = selectedDataStructure.fields
    .map(f => ({
      name: f.full_name,
      prefix: prefixes.source
    }))
    .concat(availableSystemFields);

  // Loop throuh all stages with order index smaller than selected stage index
  getters
    .dataSet()
    .dataSetStages.filter(
      s => s.order_index < selectedStageOrderIndex && s.order_index >= 0
    )
    .forEach(stage => {
      // Transform available fields by data set stage
      availableFields = transformFieldsByStage(availableFields, stage);
    });
  // If selected stage is a join stage, add foreign fields to variables
  if (
    ["LookupStage", "SimpleLookupStage"].includes(
      getters.selectedStage().stage_type
    ) &&
    getters.selectedStage().config.from
  ) {
    // Get local fields
    let localFields =
      getters.selectedStage().config.localFields?.map(f => ({
        name: f.alias,
        prefix: prefixes.local
      })) ?? [];
    // Get data structure fields
    let dsFields = getDataStructureFields(getters.selectedStage().config.from);
    // Add foreign and local fields to available field list
    availableFields = [
      ...availableFields,
      ...getForeignFields(dsFields, "", prefixes.foreign),
      ...localFields
    ];
  }
  // Filter collection children including "*" in their name
  return availableFields.filter(f => !f.name.includes("*"));
}

function getSubStageVariables() {
  // Get selected stages indices
  let selectedStageOrderIndex = getters.selectedStageOrderIndex(),
    selectedSubStageOrderIndex = getters.selectedSubStageOrderIndex();
  // Get selected subStage and selected parent stage
  let selectedStage = getters.selectedStage(),
    selectedParentStage = getters
      .dataSet()
      .dataSetStages.find(s => s.order_index === selectedStageOrderIndex);
  // Get data structure id
  let selectedDataStructureId = selectedStage.config.from;
  // Get data structure fields as initially available fields
  let availableFields =
    getters
      .dataStructures()
      .find(ds => ds.id === selectedDataStructureId)
      ?.fields.map(f => ({ name: f.full_name, prefix: prefixes.foreign })) ??
    [];
  // Merge available fields with configured source fields
  availableFields = [
    ...(selectedParentStage.config.localFields?.map(f => ({
      name: f.alias,
      prefix: prefixes.local
    })) ?? []),
    ...availableFields
  ];
  // Loop through subStages with order index smaller than selected subStage
  selectedParentStage.subStages
    .filter(
      s => s.order_index < selectedSubStageOrderIndex && s.order_index >= 0
    )
    .forEach(subStage => {
      availableFields = transformFieldsByStage(
        availableFields,
        subStage,
        selectedStageOrderIndex
      );
    });
  // Filter collection children including "*" in their name
  return availableFields.filter(f => !f.name.includes("*"));
}

function setVariablesInStore(fields) {
  // Transform fields into variables format
  fields = fields.map(f => ({
    text: f.prefix + "." + f.name,
    value: f.prefix + "." + f.name
  }));
  fields.sort((a, b) => (a.text > b.text ? 1 : -1));
  let valueObject = {};
  fields.forEach(field => {
    _.set(valueObject, field.text, "");
  });
  const payload = {
    name: "dataSetFields",
    json: true,
    variables: valueObject
  };
  // Push variables to store
  actions[SET_CUSTOM_VARIABLES](payload);
}

function transformFieldsByStage(availableFields, stage) {
  // Get config and stage type
  let config = stage.config,
    stageType = stage.stage_type;
  // Call function to transform available fields based on stage types
  switch (stageType) {
    // case "AddFieldsStageTransform":
    //   return AddFieldsStageTransform(availableFields, config);
    case "SelectStage":
      return SelectStageTransform(availableFields, config);
    case "GroupStage":
      return GroupStageTransform(availableFields, config);
    case "LookupStage":
      return LookupStageTransform(availableFields, config, stage);
    case "SimpleLookupStage":
      return SimpleLookupStageTransform(availableFields, config);
    case "SwitchStage":
      return SwitchStageTransform(availableFields, config);
    case "TransformStage":
      return TransformStageTransform(availableFields, config);
    case "UnwindStage":
      return UnwindStageTransform(availableFields, config);
    default:
      return availableFields;
  }
}

// ---------- Legacy ----------
// function AddFieldsStageTransform(availableFields, config) {
//   // Merge new fields with those already available
//   return [
//     ...config.fields.map(f => ({ name: f.alias, prefix: prefixes.output })),
//     ...availableFields
//   ];
// }
function SelectStageTransform(availableFields, config) {
  return getFieldsWithChildren(config.fields, availableFields);
}
function GroupStageTransform(availableFields, config) {
  // Return only group fields and aggregation fields
  // ---------- Legacy ----------
  // let idFields = getFieldsWithChildren(
  //   config.idField ?? [],
  //   availableFields,
  //   prefixes.output + "._id"
  // );
  // let groupFields = getFieldsWithChildren(
  //   config.groupFields ?? [],
  //   availableFields
  // );
  let idFields = (config.idField ?? []).map(f => ({
    name: f.alias,
    prefix: prefixes.output
  }));
  let groupFields = (config.groupFields ?? []).map(f => ({
    name: f.alias,
    prefix: prefixes.output
  }));
  return [...idFields, ...groupFields];
}
function LookupStageTransform(availableFields, config, stage) {
  if (!config.from || !config.alias) {
    return availableFields;
  }
  // Get fields of data structure, prefixed with configured alias
  let foreignFields = getDataStructureFields(config.from);
  // Loop through subStages and transform fields
  stage.subStages.forEach(subStage => {
    foreignFields = transformFieldsByStage(foreignFields, subStage);
  });
  let lookupFields = getForeignFields(
    foreignFields,
    config.alias,
    prefixes.output
  );
  // Return fields
  return [...lookupFields, ...availableFields];
}
function SimpleLookupStageTransform(availableFields, config) {
  let dsFields = getDataStructureFields(config.from);
  // Get fields of data structure, prefixed with configured alias
  let dataStructureFields =
    dsFields.length && config.alias
      ? getForeignFields(dsFields, config.alias, prefixes.output)
      : [];
  return [...dataStructureFields, ...availableFields];
}
function SwitchStageTransform(availableFields, config) {
  if (!config.alias) {
    return availableFields;
  }
  let categoryField = {
    name: config.alias,
    prefix: prefixes.output
  };
  return [categoryField, ...availableFields];
}
function TransformStageTransform(availableFields, config) {
  // Merge new fields with those already available
  return [
    ...config.transformer
      .filter(f => !!f.alias)
      .map(f => ({
        name: f.alias,
        prefix: prefixes.output
      })),
    ...availableFields
  ];
}
function UnwindStageTransform(availableFields, config) {
  // Get field name raw
  let fieldName = variableRaw(config.field);
  // Get collection's children to unwind
  let unwindFields = availableFields.filter(f =>
    f.name.startsWith(fieldName + ".*")
  );
  // Split every name and remove the collection asterisk
  unwindFields = unwindFields.map(field => {
    let asteriskIndex = field.name.indexOf(".*");
    // Remove dot and asterisk from name
    let name =
      field.name.substring(0, asteriskIndex) +
      field.name.substring(asteriskIndex + 2, field.name.length);
    return { name: name, prefix: prefixes.output };
  });
  // Merge new fields with those already available
  return [
    // Parent field with output prefix
    { name: fieldName, prefix: prefixes.output },
    ...unwindFields,
    // Filter children and parent element
    ...availableFields.filter(
      f => f.name !== fieldName && !f.name.startsWith(fieldName + ".")
    )
  ];
}

function getFieldsWithChildren(
  fields,
  availableFields,
  prefix = prefixes.output
) {
  let newFields = [];
  fields.forEach(field => {
    // Skip if no alias is set
    if (!field.alias) {
      return;
    }
    // Add new alias with output prefix
    newFields.push({ name: field.alias, prefix: prefix });
    // Get selected field without curly brackets and prefix
    let selectedField = variableRaw(field.field);
    // Filter available fields to check if children of field are present
    let children = availableFields
      .filter(f => f.name.startsWith(selectedField + "."))
      .map(f => ({ name: f.name, prefix: prefix }));
    // Add children to fields
    newFields.push(...children);
  });
  return newFields;
}

// Removes curly brackets and prefix from variable
function variableRaw(variable) {
  variable = variable.replace("{{", "").replace("}}", "").trim();
  variable = variable.split(".");
  variable.shift();
  return variable.join(".");
}

function getDataStructureFields(dataStructureId) {
  // Get data structure fields in correct dataSets structure
  return (
    getters.dataStructures().find(ds => ds.id === dataStructureId)?.fields ?? []
  ).map(f => ({
    name: f.full_name,
    prefix: prefixes.foreign
  }));
}

function getForeignFields(fields, alias = "", prefix = prefixes.output) {
  // Get fields of data structure, prefixed with configured alias
  // Add alias as extra field to make field available to be unwind (fields with "*" get filtered)
  return [
    { name: alias, prefix: prefix },
    ...fields.map(f => ({
      name: alias ? alias + ".*." + f.name : f.name,
      prefix: prefix
    }))
  ];
}
