<template>
  <div>
    <div class="position-relative">
      <!-----    START: Item media    ----->
      <div
        class="timeline-media"
        :class="[{ active: isSelected }, draggableHandleClass]"
        style="left: -75px"
        @click="setSelectedStage"
      >
        <i :class="stageIcons[stage.stage_type]"></i>
        <span v-if="validationIcon.length" class="validation-icon">
          <i :class="validationIcon" />
        </span>
      </div>
      <!-----    END: Item media    ----->
      <!-----    START: Item content    ----->
      <div class="timeline-content">
        <div class="row align-items-center my-0">
          <div class="col-lg-8 col-12 py-0">
            <!-----    START: Label    ----->
            <div class="text-muted">
              {{ stageLabel }}
            </div>
            <!-----    END: Label    ----->
            <!-----    START: Description    ----->
            <div class="text-muted text-caption">
              {{ stage.description }}
            </div>
            <!-----    END: Description    ----->
          </div>
          <div class="col-lg-4 col-12 text-right pb-0 pt-3 pt-lg-0">
            <!-----    START: Test button    ----->
            <button
              class="btn btn-primary btn-sm m-3 text-nowrap"
              :class="{ disabled: stage.disabled }"
              @click="test"
              @mouseenter="setStageDisabledStatus"
              @mouseleave="removeStageDisabledStatus"
            >
              {{ $t("dataSets.testStage") }}
            </button>
            <!-----    END: Test button    ----->
            <!-----    START: Remove button    ----->
            <button
              class="btn-icon text-muted text-hover-primary align-middle mx-auto"
              :class="{ invisible: orderIndex < 0 }"
              @click="remove"
            >
              <i class="fal fa-trash" />
            </button>
            <!-----    END: Remove button    ----->
          </div>
        </div>
      </div>
      <!-----    END: Item content    ----->
    </div>
  </div>
</template>

<script>
import DataSets from "@/components/DataSets/dataSets";
import { setStageConfigsByReturnKey } from "@/components/DataSets/Helper/returnKey";
import {
  DECREMENT_IS_BUSY,
  INCREMENT_IS_BUSY,
  REMOVE_DATA_SET_STAGE,
  SELECT_STAGE,
  SET_DEBUG,
  SET_TEST_STAGES,
  UPDATE_STAGE,
  VALIDATE_STAGES
} from "@/core/services/store/dataSets.module";
import { mapActions, mapGetters } from "vuex";
import { setVariablesByStage } from "@/components/DataSets/Helper/functions";
import _ from "lodash";
import i18nService from "@/core/services/i18n.service";

export default {
  components: {},
  props: {
    stage: {
      type: Object,
      default: () => ({})
    },
    orderIndex: {
      type: Number,
      default: 0
    },
    parentOrderIndex: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    i18nService() {
      return i18nService;
    },
    ...mapGetters("dataSets", [
      "dataSet",
      "stages",
      "stageIcons",
      "selectedStage",
      "selectedSubStage",
      "selectedStageOrderIndex",
      "selectedSubStageOrderIndex",
      "parameters"
    ]),
    isSelected: function () {
      return (
        // Either this stage is selected stage
        (this.stage.order_index === this.selectedStageOrderIndex &&
          this.selectedSubStageOrderIndex === null &&
          this.parentOrderIndex === null) ||
        // Or this stage is selected subStage
        (this.parentOrderIndex === this.selectedStageOrderIndex &&
          this.stage.order_index === this.selectedSubStageOrderIndex)
      );
    },
    validationIcon: function () {
      let icon = "";
      if (this.stage.valid === true) {
        icon = "fal fa-circle-check text-success";
      } else if (this.stage.valid === false) {
        icon = "fal fa-circle-xmark text-danger";
      }
      return icon;
    },
    draggableHandleClass: function () {
      if (this.stage.order_index < 0) {
        return "";
      }
      return (
        "draggable " + (this.parentOrderIndex === null ? "stage" : "sub-stage")
      );
    },
    stageLabel: function () {
      const activeLanguage = i18nService.getActiveLanguage();
      const { label, stage_type } = this.stage;
      // Sets translation or the label of the stage, fallback is the type of stage
      return label?.[activeLanguage] || label || stage_type;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [
      DECREMENT_IS_BUSY,
      INCREMENT_IS_BUSY,
      SET_DEBUG,
      SELECT_STAGE,
      REMOVE_DATA_SET_STAGE,
      SET_TEST_STAGES,
      UPDATE_STAGE,
      VALIDATE_STAGES
    ]),
    setSelectedStage() {
      const payload = {
        stageOrderIndex: this.parentOrderIndex ?? this.orderIndex,
        subStageOrderIndex:
          this.parentOrderIndex !== null ? this.orderIndex : null
      };
      this[SELECT_STAGE](payload);
      this.$nextTick().then(() => setVariablesByStage());
    },
    async test() {
      let isValid = await this.validateConfig();
      if (!isValid) {
        return;
      }

      // Get correct index
      let orderIndex = this.parentOrderIndex ?? this.orderIndex,
        subStageOrderIndex =
          this.parentOrderIndex !== null ? this.orderIndex : null;
      // Get array index, because this.index is order_index
      let index = this.dataSet.dataSetStages
        .map(s => s.order_index)
        .indexOf(orderIndex);
      let subStageIndex = null;
      if (subStageOrderIndex !== null) {
        subStageIndex = this.dataSet.dataSetStages[index].subStages
          .map(s => s.order_index)
          .indexOf(subStageOrderIndex);
      }
      this[INCREMENT_IS_BUSY]();
      // Get data set and dataSetStages
      let dataSet = _.cloneDeep(this.dataSet);
      let dataSetStages = dataSet.dataSetStages.slice(0, index + 1);

      // If subStage was clicked, do not send following subStages
      if (subStageIndex !== null) {
        dataSet.dataSetStages[index].subStages = dataSet.dataSetStages[
          index
        ].subStages.slice(0, subStageIndex + 1);
      }
      // Only get stages before selected stage
      const stages = setStageConfigsByReturnKey(dataSetStages, this.stages);
      // Set stages to test in store
      this[SET_TEST_STAGES](_.cloneDeep(stages));
      const params = this.$store.getters["route/requestParams"]();

      const payload = {
        data_structure_id: this.dataSet.data_structure_id,
        dataSetStages: stages,
        page: params?.page ?? 1,
        limit: params?.perPage ?? 15,
        parameters: this.parameters
      };
      DataSets.runStages(payload)
        .then(response => {
          if (response.error) {
            this.$error(response.error);
          }
          this[SET_DEBUG](response);
          this[DECREMENT_IS_BUSY]();
        })
        .catch(error => {
          this.$error(error);
          this[DECREMENT_IS_BUSY]();
        });
    },
    setStageDisabledStatus() {
      // Get correct index
      let index = this.parentIndex ?? this.index;
      // Get array index, because this.index is order_index
      index = this.dataSet.dataSetStages.map(s => s.order_index).indexOf(index);
      // Get data set and dataSetStages
      let dataSet = _.cloneDeep(this.dataSet);
      let dataSetStages = dataSet.dataSetStages.slice(
        index + 1,
        dataSet.dataSetStages.length
      );
      // Only get stages before selected stage
      const stages = setStageConfigsByReturnKey(dataSetStages, this.stages);

      stages.forEach(stage => {
        if (stage.hasSubStagesEnabled) {
          stage.subStages.forEach(subStage => {
            subStage.disabled = true;
            const payloadSubStage = {
              index: subStage.order_index + 1,
              value: subStage
            };
            this[UPDATE_STAGE](payloadSubStage);
          });
        }
        stage.disabled = true;
        const payload = { index: stage.order_index + 1, value: stage };
        this[UPDATE_STAGE](payload);
      });
    },
    removeStageDisabledStatus() {
      const stages = this.dataSet.dataSetStages;
      stages.forEach(stage => {
        if (stage.hasSubStagesEnabled) {
          stage.subStages.forEach(subStage => {
            subStage.disabled = false;
            const payloadSubStage = {
              index: subStage.order_index + 1,
              value: subStage
            };
            this[UPDATE_STAGE](payloadSubStage);
          });
        }
        stage.disabled = false;
        const payload = { index: stage.order_index + 1, value: stage };
        this[UPDATE_STAGE](payload);
      });
    },
    remove() {
      // Get parent stage if exists
      let parentStage =
        this.parentOrderIndex !== null
          ? _.cloneDeep(
            this.dataSet.dataSetStages.find(
              p => p.order_index === this.parentOrderIndex
            )
          )
          : null;
      // Get stage to delete either by parentStage or full list
      let stage = (parentStage?.subStages ?? this.dataSet.dataSetStages).find(
        s => s.order_index === this.orderIndex
      );
      this.$swal
        .fire({
          title: this.$t("dataSets.deleteStageText", {
            name: stage.label
          }),
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#5b64ee",
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete")
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          if (parentStage) {
            // Remove stage from subStages
            let index = parentStage.subStages
              .map(s => s.order_index)
              .indexOf(this.orderIndex);
            parentStage.subStages.splice(index, 1);
            // Update order indices of other subStages
            parentStage.subStages = parentStage.subStages.map((subStage, i) => {
              subStage.order_index = i;
              return subStage;
            });
            const payload = {
              orderIndex: this.parentOrderIndex,
              value: parentStage
            };
            this[UPDATE_STAGE](payload);
          } else {
            // Remove stage by index
            this[REMOVE_DATA_SET_STAGE](this.orderIndex);
          }
        });
    },
    async validateConfig() {
      return await this[VALIDATE_STAGES]()
        .then(errors => {
          if (!errors.length) {
            return true;
          }
          this.$toast.fire({
            icon: "error",
            title: this.$t("dataSets.configurationHasErrors"),
            html: `<ul><li>${errors.join("</li><li>")}</li></ul>`
          });
          return false;
        })
        .catch(error => {
          this.$error(error);
          return false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline-media {
  span {
    position: absolute;
    bottom: -3px;
    right: -3px;
    i {
      background-color: #fff;
      clip-path: circle(11px);
    }
  }
}
</style>
