<template>
  <div>
    <DataSetsCreate v-if="$route.name === 'projectDataSetsCreate'" />
    <DataSetsDetails v-else-if="$route.name === 'projectDataSetsDetails'" />
    <DataSetsEditor v-else-if="$route.name === 'projectDataSetsEditor'" />
    <DataSetsTable v-else />
  </div>
</template>

<script>
import DataSetsTable from "@/components/DataSets/Table.vue";
import DataSetsCreate from "@/components/DataSets/Create.vue";
import DataSetsDetails from "@/components/DataSets/Details.vue";
import DataSetsEditor from "@/components/DataSets/Editor/Index.vue";

export default {
  components: {
    DataSetsTable,
    DataSetsCreate,
    DataSetsDetails,
    DataSetsEditor
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
