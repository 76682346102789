<template>
  <div class="position-relative pb-5">
    <!------------ START: Add condition line ------------>
    <div class="connection-line noninteractive">
      <svg>
        <path
          :d="connectionPoints"
          fill="none"
          stroke="#EBEDF3"
          stroke-width="2"
        />
      </svg>
    </div>
    <!------------ END: Add condition line ------------>
    <!------------ START: Overlay to hide border ------------>
    <div
      v-if="parentStageOrderIndex === 0"
      class="position-absolute bg-white h-100"
      style="width: 10px; left: -55px"
    ></div>
    <!------------ END: Overlay to hide border ------------>
    <div class="timeline timeline-3">
      <div class="timeline-items">
        <!-----    START: Draggable items    ----->
        <draggable
          :key="draggableKey"
          v-model="subStages"
          handle=".timeline-media.sub-stage"
          animation="200"
          @start="dragStart"
          @end="dragEnd"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <div
              v-for="(stage, i) in subStages"
              :key="i"
              :class="[
                'timeline-item timeline-item-' + i,
                i + 1 === subStages.length ? 'pb-5' : ''
              ]"
            >
              <!-----    START: Stage    ----->
              <Stage
                :stage="stage"
                :order-index="stage.order_index"
                :parent-order-index="parentStageOrderIndex"
              />
              <!-----    END: Stage    ----->
            </div>
          </transition-group>
        </draggable>
        <!-----    START: Add stage button    ----->
        <AddStage :parent-order-index="parentStageOrderIndex" />
        <!-----    END: Add stage button    ----->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  SELECT_STAGE,
  SET_DEBUG,
  UPDATE_STAGE
} from "@/core/services/store/dataSets.module";
import Stage from "@/components/DataSets/Components/Stage.vue";
import AddStage from "@/components/DataSets/Components/AddStage.vue";
import _ from "lodash";

export default {
  components: { AddStage, Stage },
  props: {
    parentStageOrderIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      connectionPoints: "M0,80 L77,0",
      draggableKey: 1,
      drag: false
    };
  },
  computed: {
    ...mapGetters("dataSets", [
      "dataSet",
      "stageIcons",
      "stages",
      "selectedStage",
      "selectedSubStage"
    ]),
    parentStage: function () {
      return Object.assign(
        {},
        this.dataSet.dataSetStages.find(
          s => s.order_index === this.parentStageOrderIndex
        )
      );
    },
    subStages: {
      get: function () {
        return [...this.parentStage.subStages];
      },
      set: function (subStages) {
        this.updateOrderIndices(subStages);
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [UPDATE_STAGE, SELECT_STAGE, SET_DEBUG]),
    dragStart() {
      this.drag = true;
      const payload = { stageOrderIndex: null, subStageOrderIndex: null };
      this[SELECT_STAGE](payload);
    },
    dragEnd() {
      this.drag = false;
      this.draggableKey++;
    },
    updateOrderIndices(subStages) {
      // Get parent stage
      let parentStage = _.cloneDeep(
        this.dataSet.dataSetStages.find(
          s => s.order_index === this.parentStageOrderIndex
        )
      );
      // Update each order index of subStage to current index in array
      parentStage.subStages = subStages.map((subStage, i) => {
        subStage.order_index = i;
        return subStage;
      });
      // Update parent stage in store
      const payload = {
        orderIndex: this.parentStageOrderIndex,
        value: parentStage
      };
      this[UPDATE_STAGE](payload);
    }
  }
};
</script>

<style lang="scss">
.connection-line {
  svg {
    position: absolute;
    top: calc(100% - 45px);
    height: 75px;
    width: 77px;
    left: -50px;
  }
}
</style>
