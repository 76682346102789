<template>
  <div>
    <div
      v-if="!selectedStage || selectedStage.stage_type === 'SourceStage'"
      class="text-center h5 text-muted mt-7"
    >
      {{ $t("dataSets.selectStage") }}
    </div>
    <div v-else>
      <div class="h5 text-center mb-5">{{ baseStage?.label }}</div>
      <b-tabs :value="1">
        <b-tab :title="$t('dataSets.description')" class="p-3">
          <FormHelper
            :key="formKey"
            v-model="stage"
            :form="formDescription"
            :config="formConfigDescription"
          />
        </b-tab>
        <b-tab :title="$t('dataSets.configuration')" class="p-3">
          <FormHelper
            :key="formKey"
            v-model="stageConfig"
            :form="baseStage.config"
            :config="formConfigConfiguration"
            @change="onChange"
          />
          <!------------ START: SelectStage - Select all fields ------------>
          <div
            v-if="
              stage.stage_type === 'SelectStage' && !stageConfig.fields.length
            "
            class="row"
          >
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <div
                class="btn btn-link text-muted text-hover-primary p-0 mt-n10"
                @click="selectAllFields"
              >
                <i class="fal fa-plus" />
                {{ $t("dataSets.selectAllFields") }}
              </div>
            </div>
          </div>
          <!------------ END: SelectStage - Select all fields ------------>
        </b-tab>
        <b-tab :title="$t('dataSets.condition')" class="p-3">
          <FormHelper
            :key="formKey"
            v-model="conditions"
            :form="formConditions"
            :config="formConfigConditions"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { UPDATE_SELECTED_STAGE } from "@/core/services/store/dataSets.module";
import _ from "lodash";
import { customRegex } from "@/components/Tools/FormHelper/Helper/constants";
import { setVariablesByStage } from "@/components/DataSets/Helper/functions";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      formDescription: [
        {
          name: "label",
          label: "label",
          type: "text"
        },
        {
          name: "description",
          label: "description",
          type: "textarea"
        }
      ],
      formConfigDescription: {
        enableVariables: false,
        snippetPrefix: "dataSets"
      },
      formConfigConfiguration: {
        enableVariables: true,
        snippetPrefix: "dataSets",
        presetValues: true,
        customVariables: ["dataSetFields", "dataSetParameters"],
        filterHiddenValues: true,
        fullWidth: true,
        enableTypecast: true
      },
      formConfiguration: [],
      formConditions: [
        {
          name: "conditions",
          label: "condition",
          type: "condition"
        }
      ],
      formConfigConditions: {
        enableVariables: true,
        snippetPrefix: "dataSets",
        customVariables: ["dataSetParameters"],
        enableTypecast: true,
        fullWidth: true
      },
      formKey: 0
    };
  },
  computed: {
    ...mapGetters("dataSets", [
      "selectedStage",
      "selectedStageOrderIndex",
      "selectedSubStage",
      "selectedSubStageOrderIndex",
      "stages"
    ]),
    ...mapGetters("variables", ["customVariablesSet"]),
    baseStage: function () {
      // Get either selected parent or subStage
      let stage = this.selectedSubStage ?? this.selectedStage;
      // If a stage is selected, find its template from stage library
      return stage
        ? Object.values(this.stages).find(
            baseStage => baseStage.value === stage.stage_type
          )
        : null;
    },
    stage: {
      get: function () {
        // Return copy by value of selected stage
        return Object.assign({}, this.selectedSubStage ?? this.selectedStage);
      },
      set: function (value) {
        // If a subStage is selected
        if (this.selectedSubStageOrderIndex !== null) {
          // Get parent stage
          let stage = this.selectedStage;
          // Update new configuration of subStage
          stage.subStages[this.selectedSubStageOrderIndex] = value;
          // Assign parent stage to value
          value = stage;
        }
        this[UPDATE_SELECTED_STAGE](value);
      }
    },
    stageConfig: {
      get: function () {
        // Get config of selected stage
        return this.stage.config;
      },
      set: function (value) {
        // Get stage
        let stage = this.stage;
        // Set updated config
        stage.config = value;
        // Update stage
        this.stage = stage;
      }
    },
    conditions: {
      get: function () {
        // Get config of selected stage
        return Object.assign({}, this.stage);
      },
      set: function (value) {
        // Get stage
        let stage = this.stage;
        // Set updated config
        stage.conditions = value.conditions;
        // Update stage
        this.stage = stage;
      }
    }
  },
  watch: {
    selectedStageOrderIndex: function () {
      this.formKey++;
    },
    selectedSubStageIndex: function () {
      this.formKey++;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [UPDATE_SELECTED_STAGE]),
    onChange(payload) {
      this.$nextTick().then(() => {
        this.setAutoAlias(payload);
        this.checkVariablesReload(payload);
      });
    },
    setAutoAlias(payload) {
      let { name, value, valuePath } = payload;
      // Return if value is not nested or is not a variable
      if (valuePath === "" || !customRegex.variable.test(value)) {
        return;
      }
      value = value.slice(2, -2);
      // Get path split and name of value field
      let pathSplit = valuePath.split(".");
      let fieldName = pathSplit[pathSplit.length - 1];
      // If field's name is not "field", return
      if (fieldName !== "field") {
        return;
      }
      // Pop "field" from path
      pathSplit.pop();
      let path = pathSplit.join(".");
      // Get path to alias field and its value
      let aliasPath = name + "." + path + ".alias";
      let alias = _.get(this.stageConfig, aliasPath);
      // If alias is not an empty string, return
      if (alias !== "") {
        return;
      }
      // Get clone of config
      let config = _.cloneDeep(this.stageConfig);
      // Set new auto alias
      let valueSplit = value.split(".");
      alias = valueSplit[valueSplit.length - 1];
      _.set(config, aliasPath, alias);
      this.stageConfig = config;
    },
    selectAllFields() {
      let variablesSet = this.customVariablesSet("dataSetFields");
      let sourceFields = variablesSet.variables.find(
        v => v.text === "source"
      ).value;
      this.stageConfig.fields = Object.keys(sourceFields)
        .sort()
        .map(f => ({
          field: `{{source.${f}}}`,
          alias: f
        }));
    },
    checkVariablesReload(payload) {
      if (
        !["LookupStage", "SimpleLookupStage"].includes(
          this.selectedStage.stage_type
        ) ||
        (payload.name !== "from" && payload.name !== "localFields")
      ) {
        return;
      }
      // If selected stage is a join and changed field was data structure selection
      // or if local fields were added,
      // reload variables
      this.$nextTick().then(() => setVariablesByStage());
    }
  }
};
</script>
