<template>
  <div>
    <Header
      :title="$t('dataSets.editDataSet').toString()"
      :subtitle="subtitle"
      :items="headerButtons"
      :show-back-button="true"
      @back="$router.push({ name: 'projectDataSets' })"
      @save="submit"
      @showVersions="onShowVersions"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="dataSet"
          :config="formConfig"
          :form="form"
        />
      </div>
    </div>

    <BackupModal
      v-if="dataSet"
      v-model="showVersionsModal"
      :object="dataSet"
      classname="DataSet"
    />
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { mapGetters } from "vuex";
import DataSets from "@/components/DataSets/dataSets";
import DataStructures from "@/components/DataStructures/dataStructures";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: { BackupModal, Header, FormHelper },
  props: {},
  data() {
    return {
      dataSet: {},
      form: [
        {
          name: "name",
          type: "text",
          label: "name",
          validations: {
            required: true
          }
        },
        {
          name: "projects",
          type: "multiselect",
          label: "projects",
          options: [],
          validations: {
            required: true
          }
        },
        {
          name: "data_structure_id",
          type: "select",
          label: "selectDataStructure",
          options: [],
          validations: {
            required: true
          },
          disabled: true
        }
      ],
      formConfig: {
        snippetPrefix: "dataSets",
        enableVariables: false
      },
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "projects",
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    },
    subtitle: function () {
      if (!this.dataSet) {
        return "";
      }

      let subtitleParts = [];
      if (this.dataSet.updated_by_user?.full_name) {
        subtitleParts.push(this.dataSet.updated_by_user?.full_name);
      }
      if (this.dataSet.updated_at) {
        subtitleParts.push(formatDate(this.dataSet.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    projects: function () {
      this.setProjectOptions();
    },
    selectedPresetVersion: function () {
      this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadDataSet();
      this.loadDataStructures();
      this.setProjectOptions();
    },
    loadDataSet() {
      addEventToLoadingQueue({ key: "loadDataSet" });
      const id = this.$route.params.id;
      DataSets.get(id)
        .then(response => {
          this.dataSet = response.data;
          this.dataSet.projects = this.dataSet.projects.map(
            project => project.id
          );
          removeEventFromLoadingQueue({ key: "loadDataSet" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadDataStructures() {
      addEventToLoadingQueue({ key: "loadDataStructures" });
      const params = { noPagination: true };
      if (this.dataSet.projects.length > 0) {
        params.projects = this.dataSet.projects;
      }
      // Get all datastructures
      DataStructures.getAll(params)
        .then(response => {
          // Set datastructures as select options
          this.form[2].options = response.data.map(option => ({
            label: option.label,
            value: option.id
          }));
          removeEventFromLoadingQueue({ key: "loadDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    setProjectOptions() {
      this.form[1].options = this.projects.map(project => ({
        label: project.name,
        value: project.id
      }));
    },
    cancel() {
      this.$router.back();
    },
    submit() {
      let valid = this.$refs.form.validate();
      if (valid === true) {
        this.updateDataSet();
      }
    },
    async updateDataSet() {
      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(this.dataSet);
      if (!success) {
        return;
      }
      addEventToLoadingQueue({ key: "updateDataSet" });

      DataSets.update(this.dataSet.id, data)
        .then(response => {
          this.dataSet = response.data;
          removeEventFromLoadingQueue({
            key: "updateDataSet",
            type: "success",
            prefix: "dataSets",
            name: "savedSuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
