<template>
  <b-modal
    id="parameter-modal"
    v-model="show"
    centered
    :title="$t('dataSets.addParameter')"
    no-close-on-backdrop
    size="lg"
    @show="setEdit"
  >
    <FormHelper ref="form" v-model="parameter" :form="form" :config="config" />
    <template #modal-footer>
      <button class="btn btn-secondary" @click="closeModal">
        {{ $t("general.cancel") }}
      </button>
      <button class="btn btn-primary" @click="save">
        {{ $t("general.save") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { mapActions, mapGetters } from "vuex";
import { SET_PARAMETERS } from "@/core/services/store/dataSets.module";

const formTemplate = [
  {
    name: "name",
    label: "name",
    type: "text",
    disabled: false,
    validations: {
      required: true
    }
  },
  {
    name: "type",
    label: "type",
    type: "select",
    options: [
      { label: "typeText", value: "text" },
      { label: "typeNumber", value: "number" },
      { label: "typeJson", value: "json" },
      { label: "typeCondition", value: "condition" }
    ],
    default: "text"
  },
  {
    name: "value",
    label: "value",
    type: "text",
    strict: false,
    dependsOn: [{ name: "type", values: ["text"] }]
  },
  {
    name: "value",
    label: "value",
    type: "number",
    dependsOn: [{ name: "type", values: ["number"] }]
  },
  {
    name: "value",
    label: "value",
    type: "json",
    returnJson: true,
    fields: [{ name: "key" }, { name: "value" }],
    dependsOn: [{ name: "type", values: ["json"] }]
  },
  {
    name: "value",
    label: "value",
    type: "condition",
    strict: false,
    operators: [
      {
        name: "$eq",
        label: "==",
        hasParameters: true
      },
      {
        name: "$gt",
        label: ">",
        hasParameters: true
      },
      {
        name: "$gte",
        label: ">=",
        hasParameters: true
      },
      {
        name: "$in",
        label: "IN",
        hasParameters: true
      },
      {
        name: "$lt",
        label: "<",
        hasParameters: true
      },
      {
        name: "$lte",
        label: "<=",
        hasParameters: true
      },
      {
        name: "$ne",
        label: "!=",
        hasParameters: true
      },
      {
        name: "$nin",
        label: "NOT IN",
        hasParameters: true
      },
      {
        name: "$exists",
        label: "EXISTS",
        hasParameters: false
      }
    ],
    dependsOn: [{ name: "type", values: ["condition"] }]
  }
];
export default {
  components: { FormHelper },
  model: {
    prop: "show"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    editParameter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      parameter: {
        name: "",
        type: "",
        value: ""
      },
      config: {
        enableVariables: false,
        snippetPrefix: "dataSets.parametersModal"
      }
    };
  },
  computed: {
    ...mapGetters("dataSets", ["parameters"]),
    form: function () {
      let form = formTemplate;
      form[0].disabled = this.isEdit;
      return form;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [SET_PARAMETERS]),
    closeModal() {
      this.$emit("input", false);
      // Reset parameter
      this.parameter = {
        name: "",
        type: "",
        value: null
      };
    },
    save() {
      let errors = this.$refs.form.validate();
      if (errors.length) {
        return;
      }
      this.isEdit ? this.updateParameter() : this.addParameter();
    },
    addParameter() {
      // If name already exists, return with error message
      if (Object.hasOwn(this.parameters, this.parameter.name)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("dataSets.parametersModal.duplicateName")
        });
        return;
      }
      // Add parameter to dataSet parameters
      this.updateParameter();
    },
    updateParameter() {
      // Add parameter to dataSet parameters
      let parameters = [...this.parameters];
      if (this.isEdit) {
        // If editing, find edited parameter and update value
        let index = parameters.map(p => p.name).indexOf(this.parameter.name);
        parameters[index] = this.parameter;
      } else {
        // Add parameter to array
        parameters.push(this.parameter);
      }
      // Update store
      this[SET_PARAMETERS](parameters);
      // Close modal
      this.closeModal();
    },
    setEdit() {
      if (!this.isEdit) {
        return;
      }
      Object.assign(this.parameter, this.editParameter);
    }
  }
};
</script>
