<template>
  <div>
    <!-----    START: Header    ----->
    <Header
      :title="dataSet.name"
      :subtitle="subtitle"
      :items="headerButtons"
      :show-back-button="true"
      @back="$router.push({ name: 'projectDataSets' })"
      @save="save"
      @showVersions="onShowVersions"
    />
    <!-----    END: Header    ----->
    <!-----    START: Card    ----->
    <div class="card card-custom">
      <!-----    START: Content    ----->
      <div class="card-body">
        <div class="row">
          <!-----    START: Stages    ----->
          <div class="col-xl-6 col-lg-5 col-md-12">
            <Stages />
          </div>
          <!-----    END: Stages    ----->
          <v-divider vertical />
          <!-----    START: Configuration    ----->
          <div class="col-xl-6 col-lg-7 col-md-12">
            <Configuration />
          </div>
          <!-----    END: Configuration    ----->
        </div>
        <div class="row my-10"></div>
        <div class="row">
          <Debug class="col" />
        </div>
      </div>
      <!-----    END: Content    ----->
    </div>
    <!-----    END: Card    ----->

    <BackupModal
      v-if="dataSet"
      v-model="showVersionsModal"
      :object="dataSet"
      classname="DataSet"
    />
  </div>
</template>

<script>
import DataSets from "@/components/DataSets/dataSets";
import { mapActions, mapGetters } from "vuex";
import {
  CLEAR_ALL,
  DECREMENT_IS_BUSY,
  INCREMENT_IS_BUSY,
  SELECT_STAGE,
  SET_DATA_SET,
  SET_DATA_STRUCTURES,
  SET_SELECTED_DATA_STRUCTURE_ID,
  SET_STAGES,
  VALIDATE_STAGES
} from "@/core/services/store/dataSets.module";
import Stages from "@/components/DataSets/Editor/Stages.vue";
import Configuration from "@/components/DataSets/Editor/Configuration.vue";
import Debug from "@/components/DataSets/Editor/Debug/Index.vue";
import DataStructures from "@/components/DataStructures/dataStructures";
import {
  LOAD_CONFIG_VALUES,
  LOAD_PRESET_VALUES,
  REMOVE_CUSTOM_VARIABLES
} from "@/core/services/store/variables_v1.module";
import {
  getStageConfigsByReturnKey,
  setStageConfigsByReturnKey
} from "@/components/DataSets/Helper/returnKey";
import _ from "lodash";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: { BackupModal, Header, Debug, Configuration, Stages },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters("dataSets", [
      "dataSet",
      "isBusy",
      "stages",
      "selectedStageOrderIndex"
    ]),
    ...mapGetters([
      "isPresetProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      let headerButtons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerButtons;
      }
      headerButtons.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerButtons;
    },
    subtitle: function () {
      if (!this.dataSet) {
        return "";
      }

      let subtitleParts = [];
      if (this.dataSet.updated_by_user?.full_name) {
        subtitleParts.push(this.dataSet.updated_by_user?.full_name);
      }
      if (this.dataSet.updated_at) {
        subtitleParts.push(formatDate(this.dataSet.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    selectedStageOrderIndex: function () {
      this[VALIDATE_STAGES]();
    },
    selectedPresetVersion: function () {
      this[LOAD_CONFIG_VALUES]();
      this[LOAD_PRESET_VALUES]();
      this.init();
    }
  },
  async mounted() {
    this[LOAD_CONFIG_VALUES]();
    this[LOAD_PRESET_VALUES]();
    await this.init();
  },
  beforeDestroy() {
    this[CLEAR_ALL]();
    this[REMOVE_CUSTOM_VARIABLES]("dataSetFields");
  },
  methods: {
    ...mapActions("dataSets", [
      SET_DATA_SET,
      INCREMENT_IS_BUSY,
      DECREMENT_IS_BUSY,
      CLEAR_ALL,
      SET_DATA_STRUCTURES,
      SET_SELECTED_DATA_STRUCTURE_ID,
      SELECT_STAGE,
      SET_STAGES,
      VALIDATE_STAGES
    ]),
    ...mapActions("variables", [
      LOAD_CONFIG_VALUES,
      REMOVE_CUSTOM_VARIABLES,
      LOAD_PRESET_VALUES
    ]),
    async init() {
      addEventToLoadingQueue({ key: "init" });
      // Load all asynchronous requests
      await Promise.all([
        this.loadStages(),
        this.loadDataSet(),
        this.loadDataStructures()
      ])
        // When all requests finished
        .then(([stagesResponse, dataSetResponse, dataStructureResponse]) => {
          // Set data structures
          this[SET_DATA_STRUCTURES](dataStructureResponse.data);
          // Set list of stages
          this[SET_STAGES](stagesResponse.data);
          // Get data set
          let dataSet = dataSetResponse.data;
          dataSet.projects = dataSet.projects.map(project => project.id);
          dataSet.dataSetStages = getStageConfigsByReturnKey(
            dataSet.dataSetStages,
            this.stages
          );
          // Set data set in store
          this[SET_DATA_SET](dataSet);
          this[SET_SELECTED_DATA_STRUCTURE_ID](dataSet.data_structure_id);
          removeEventFromLoadingQueue({ key: "init" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async loadDataSet() {
      let id = this.$route.params.id;
      return DataSets.get(id);
    },
    async loadDataStructures() {
      return DataStructures.getAll({ noPagination: true });
    },
    async loadStages() {
      return DataSets.stages();
    },
    async save() {
      let isValid = await this.validateConfig();
      if (!isValid) {
        return;
      }
      let dataSet = _.cloneDeep(this.dataSet);

      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(dataSet);
      if (!success) {
        return;
      }
      dataSet = data;
      addEventToLoadingQueue({ key: "save" });
      // Get data set and dataSetStages
      dataSet.dataSetStages = setStageConfigsByReturnKey(
        dataSet.dataSetStages,
        this.stages
      );
      // Update data set
      DataSets.update(dataSet.id, dataSet)
        .then(response => {
          let dataSet = response.data;
          dataSet.projects = dataSet.projects.map(project => project.id);
          dataSet.dataSetStages = getStageConfigsByReturnKey(
            dataSet.dataSetStages,
            this.stages
          );
          this[SET_DATA_SET](dataSet);
          removeEventFromLoadingQueue({
            key: "save",
            type: "success",
            prefix: "dataSets",
            name: "savedSuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async validateConfig() {
      return await this[VALIDATE_STAGES]()
        .then(errors => {
          if (!errors.length) {
            return true;
          }
          this.$toast.fire({
            icon: "error",
            title: this.$t("dataSets.configurationHasErrors"),
            html: `<ul><li>${errors.join("</li><li>")}</li></ul>`
          });
          return false;
        })
        .catch(error => {
          this.$error(error);
          return false;
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
