<template>
  <!-----    START: Add stage button    ----->
  <div class="timeline-item">
    <v-menu offset-y content-class="mt-1 shadow border">
      <template #activator="{ on, attrs }">
        <div class="timeline-media cursor-pointer" v-bind="attrs" v-on="on">
          <i class="fal fa-plus"></i>
        </div>
      </template>
      <perfect-scrollbar
        class="scroll h-100"
        style="max-height: 270px"
        :options="{ wheelPropagation: false }"
      >
        <v-list dense>
          <v-list-item
            v-for="(stage, name) in stagesFiltered"
            :key="name"
            link
            @click="addStage(stage)"
          >
            <v-list-item-content>
              <div class="d-flex flex-nowrap align-items-center">
                <div class="text-center mr-1" style="width: 25px">
                  <i :class="[stageIcons[stage.value], 'm-0']" />
                </div>
                <div>{{ stage.label }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-menu>
    <div
      class="timeline-content text-muted d-flex align-items-center"
      style="height: 50px"
    >
      {{ $t("dataSets.addStage") }}
    </div>
  </div>
  <!-----    END: Add stage button    ----->
</template>

<script>
import {
  ADD_DATA_SET_STAGE,
  SELECT_STAGE,
  UPDATE_STAGE
} from "@/core/services/store/dataSets.module";
import { mapActions, mapGetters } from "vuex";
import { setVariablesByStage } from "@/components/DataSets/Helper/functions";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";

export default {
  components: {},
  props: {
    parentOrderIndex: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dataSets", ["dataSet", "stages", "stageIcons"]),
    stagesFiltered: function () {
      let stages = Object.values(this.stages);
      if (this.parentOrderIndex !== null) {
        // If this is a sub pipeline, join stages need to be filtered from selection
        stages = stages.filter(
          stage => !["LookupStage", "SimpleLookupStage"].includes(stage.value)
        );
      }
      // Sort by label
      return stages.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
  },
  mounted() {},
  methods: {
    ...mapActions("dataSets", [ADD_DATA_SET_STAGE, SELECT_STAGE, UPDATE_STAGE]),
    addStage(stage) {
      // Create empty config object
      let config = {},
        // Get order index of new stage
        orderIndex =
          this.parentOrderIndex !== null
            ? this.dataSet.dataSetStages.find(
                s => s.order_index === this.parentOrderIndex
              ).subStages.length
            : this.dataSet.dataSetStages.length - 1;
      // Map config from stage template to new stage
      stage.config.forEach(field => {
        if (["action", "alert"].includes(field.type)) {
          return;
        }
        config[field.name] = fieldDefaults[field.type];
      });
      // Create new stage
      let value = {
        active: 1,
        config: config,
        order_index: orderIndex,
        stage_type: stage.value,
        label: stage.label,
        description: ""
      };
      // Add additional props if stage is not a subStage
      if (this.parentOrderIndex === null) {
        value.subStages = [];
        value.hasSubStagesEnabled = stage.value === "LookupStage";
      }
      if (this.parentOrderIndex !== null) {
        // Get parent stage
        let parentStage = this.dataSet.dataSetStages.find(
          s => s.order_index === this.parentOrderIndex
        );
        // Add new subStage
        parentStage.subStages.push(value);
        // Update parent stage with new subStage
        const payload = {
          orderIndex: this.parentOrderIndex,
          value: parentStage
        };
        this[UPDATE_STAGE](payload);
      } else {
        // Add stage to store
        this[ADD_DATA_SET_STAGE](value);
      }
      // Select created stage
      this.setSelectedStage(
        this.parentOrderIndex ?? orderIndex,
        this.parentOrderIndex !== null ? orderIndex : null
      );
    },
    setSelectedStage(orderIndex, subStageOrderIndex = null) {
      const payload = {
        stageOrderIndex: orderIndex,
        subStageOrderIndex: subStageOrderIndex
      };
      this[SELECT_STAGE](payload);
      this.$nextTick().then(() => setVariablesByStage());
    }
  }
};
</script>
